import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiInterceptor implements HttpInterceptor {

  constructor() { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    console.log('INTERCEPTOR');
    return next.handle(req).pipe(
      map(resp => {
        if (resp instanceof HttpResponse) {
          console.log(resp);
          return resp;
          // return  resp.clone({ body: [{title: 'Replaced data in interceptor'}] });
        }
      })
    );
  }
}