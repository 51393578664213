export class AppConfig {
    apiEndPoints = {
        getlogs: '/inventory/audit_details/',
        agents: '/inventory/master_data/get_agent_group_names/',
        consolidatedDetails: '/inventory/consolidated_count_by_service/',
        filters: '/inventory/filters/',
        genericSearch: '/inventory/generic_search/',
        genericSearchNc: '/inventory/non_compliant_generic_search/',
        globalSearch: '/inventory/global_search/',
        globalSearchNc: '/inventory/non_compliant_global_search/',
        regionList: '/inventory/account_details/region_list/',
        resourcePermission: '/inventory/resource_permission/',
        eventsManager: '/inventory/event_automation/',
        environmentList: '/inventory/environmentalist_details/',
        deleteEnvironmentList: '/inventory/environmentalist_details/delete_env/',
        getToken: '/inventory/token_details/',
        updateToken: '/inventory/token_details/update_token/',
        activeEvent: '/inventory/event_link/',
        getKeyLinks:'/inventory/master_data/get_link_keys/',
        complient: {
            EC2: '/inventory/ec2_details/',
            RDS: '/inventory/rds_details/',
            EBS: '/inventory/ebs_details/',
            LB: '/inventory/aelb_details/',
            Servers: '/inventory/onprem_server_details/',
            Networks: '/inventory/network_details/',
            Citrix: '/inventory/citrix_details/',
            MasterCI: '/inventory/master_data/',
            SFTP: '/inventory/sftp_details/',
            Subscriptions: '/inventory/subscription_details/',
            VirtualMachine: '/inventory/vm_details/',
            S3: '/inventory/s3_details/',
            Account: '/inventory/account_details/',
            VPC: '/inventory/vpc_details/',
            Digital: '/inventory/digital_assets/',
        },
        noncomplient: {
            EC2: '/inventory/non_compliant_ec2_details/',
            RDS: '/inventory/non_compliant_rds_details/',
            EBS: '/inventory/non_compliant_ebs_details/',
            LB: '/inventory/non_compliant_aelb_details/',
            Servers: '/inventory/onprem_server_details/',
            Networks: '/inventory/network_details/',
            Citrix: '/inventory/citrix_details/',
            S3: '/inventory/non_compliant_s3_details/',
            VPC: '/inventory/non_compliant_vpc_details/'
        },
        liveData: '/inventory/live_data/',
        startStop: '/inventory/instance_start_stop/',
        notificaitons: '/inventory/notification_service/',
        addUserSFTP: '/inventory/sftp_details/add_user/',
        updateUserSFTP: '/inventory/sftp_details/update_user/',
        deleteUserSFTP: '/inventory/sftp_details/delete_user/',
        addApplication: '/inventory/master_data/add_app/',
        updateApplication: '/inventory/master_data/update_app/',
        deleteApplication: '/inventory/master_data/delete_app/',
        addAccount: '/inventory/account_details/add_acc/',
        updateAccount: '/inventory/account_details/update_acc/',
        deleteAccount: '/inventory/account_details/delete_acc/'
    };
    requestTypeDataConfig = {
        instance_start_stop: {
            cols: [
                { label: 'Request ID', value: 'id', type: 'NA' },
                { label: 'Application Name', value: 'app_name', type: 'NA' },
                { label: 'Request Type', value: 'request_type', type: 'NA' },
                { label: 'Environment', value: 'env_name', type: 'NA' },
                { label: 'Requested By', value: 'requestedUserName', type: 'NA' },
                { label: 'Status', value: 'request_status', type: 'NA' },
                { label: 'Requested Date', value: 'requested_datetime', type: 'time' },
                { label: 'Approver Name', value: 'approvedUserName', type: 'NA' },
                { label: 'Requester Comments', value: 'request_comments', type: 'NA' },
                { label: 'Approved Date', value: 'approved_datetime', type: 'time' },
                { label: 'Instance ID', value: 'instance_id', type: 'NA' },
                { label: 'Approver Comments', value: 'approver_comments', type: 'NA' }

            ]
        },
        live_data: {
            cols: [
                { label: 'Request ID', value: 'id', type: 'NA' },
                { label: 'Application Name', value: 'app_name', type: 'NA' },
                { label: 'Status', value: 'job_status', type: 'NA' },
                { label: 'Requested By', value: 'requestedUserName', type: 'NA' },
            ]
        }
    };
    filterConfig = {
        filterType: {
            default: [
                { label: 'Applications', value: 'applications' },
                { label: 'Environment', value: 'environments' },
                { label: 'Resource Type', value: 'resource_types' },
                { label: 'Account', value: 'accounts' },
                { label: 'Role', value: 'roles' }
            ],
            EC2: [
                { label: 'Applications', value: 'applications' },
                { label: 'Environment', value: 'environments' },
                { label: 'Resource Type', value: 'resource_types' },
                { label: 'Account', value: 'accounts' },
                { label: 'Role', value: 'roles' }
            ],
            RDS: [
                { label: 'Applications', value: 'applications' },
                { label: 'Environment', value: 'environments' },
                { label: 'Resource Type', value: 'resource_types' },
                { label: 'Account', value: 'accounts' }
            ],
            EBS: [
                { label: 'Applications', value: 'applications' },
                { label: 'Environment', value: 'environments' },
                { label: 'Resource Type', value: 'resource_types' },
                { label: 'Account', value: 'accounts' }
            ],
            LB: [
                { label: 'Applications', value: 'applications' },
                { label: 'Environment', value: 'environments' },
                { label: 'Resource Type', value: 'resource_types' },
                { label: 'Account', value: 'accounts' }
            ],
            Servers: [
                { label: 'Applications', value: 'applications' },
                { label: 'Environment', value: 'environments' },
                { label: 'Resource Type', value: 'resource_types' },
                { label: 'Role', value: 'roles' }
            ],
            Networks: [
                { label: 'Asset Type', value: 'asset_types' },
                { label: 'Resource Type', value: 'resource_types' },
                { label: 'Serial Number', value: 'serial_number' },
                { label: 'Display Name', value: 'display_name' },
                { label: 'Location', value: 'location' },
            ],
            Citrix: [
                { label: 'Applications', value: 'applications' },
                { label: 'Environment', value: 'environments' },
                { label: 'Resource Type', value: 'resource_types' }
            ],
            MasterCI: [
                // { label: 'Applications', value: 'applications' },
                { label: 'Application Name', value: 'application_name' },
                { label: 'Application Owner', value: 'application_owner' },
                { label: 'Application Id', value: 'application_id' },
                { label: 'Development Manager', value: 'development_manager' },
                { label: 'Budget Manager', value: 'budget_manager' },
                { label: 'Status', value: 'status' },
                { label: 'Public', value: 'public' },
            ],
            SFTP: [
                { label: 'Environment', value: 'environments' }
            ],
            Subscriptions: [
                { label: 'Subscriptions', value: 'display_name' }
            ],
            VirtualMachine: [
                { label: 'Subscriptions', value: 'subscriptions' },
                { label: 'VM Names', value: 'vm_names' }
            ],
            S3: [
                { label: 'Applications', value: 'applications' },
                { label: 'Environment', value: 'environments' },
                { label: 'Resource Type', value: 'resource_types' },
                { label: 'Account', value: 'accounts' }
            ],
            Account: [
                // { label: 'Account', value: 'accounts' },
                { label: 'Account', value: 'account_name' },
                { label: 'CIA accountable', value: 'account_cia' },
                { label: 'Account Owner', value: 'account_owner' },
                { label: 'Department', value: 'account_dept' }
            ],
            VPC: [
                { label: 'Account', value: 'accounts' },
                { label: 'VPC ID', value: 'vpc_id' }
            ],
            Digital: [
                { label: 'asset_type', value: 'asset_type' }
            ]

        },
        liveDataFilters: [
            { label: 'Applications', value: 'applications' },
            { label: 'Resource Type', value: 'resource_types' }
        ]
    };
    USRegiosACcounts = [
        { "id": 16, "name": "aenetworks-techlabDR" },
        { "id": 14, "name": "aenetworks-techlab" },
        { "id": 13, "name": "aenetworks-smrsdr" },
        { "id": 12, "name": "aenetworks-smrsdev" },
        { "id": 11, "name": "aenetworks-smrs" },
        { "id": 9, "name": "aenetworks-gbstdev" },
        { "id": 10, "name": "aenetworks-gbstdr" },
        { "id": 8, "name": "aenetworks-gbst" },
        { "id": 6, "name": "aenetworks-emsdev" },
        { "id": 5, "name": "aenetworks-ems" },
        { "id": 4, "name": "aenetworks-dmgdr" },
        { "id": 3, "name": "aenetworks-dmgdev" },
        { "id": 2, "name": "aenetworks-dmg" },
        { "id": 1, "name": "aenetworks-citrix" },
        { "id": 7, "name": "aenetworks-emsdr" },
        { "id": 15, "name": "aenetworks-techlabDev" }
    ];
    resourceTypeConfig = {
        data: [
            { "name": "EC2", count: 0, category: 'cloud', filterName: [ "resource_types", "accounts" ], "resource": "EC2", value: { resource_types: { id: 1, name: "EC2", display: "EC2", resource_order: 1, category: 'cloud' }, accounts: this.USRegiosACcounts } },
            { "name": "RDS", count: 0, category: 'cloud', filterName: [ "resource_types", "accounts" ], "resource": "RDS", value: { resource_types: { id: 2, name: "RDS", display: "RDS", resource_order: 2, category: 'cloud' }, accounts: this.USRegiosACcounts } },
            { "name": "LB", count: 0, category: 'cloud', filterName: [ "resource_types", "accounts" ], "resource": "LB", value: { resource_types: { id: 3, name: "LB", display: "LB", resource_order: 3, category: 'cloud' }, accounts: this.USRegiosACcounts } },
            { "name": "EBS", count: 0, category: 'cloud', filterName: [ "resource_types", "accounts" ], "resource": "EBS", value: { resource_types: { id: 4, name: "EBS", display: "EBS", resource_order: 4, category: 'cloud' }, accounts: this.USRegiosACcounts } },
            { "name": "Servers", count: 0, category: 'onprem', filterName: [ "resource_types" ], "resource": "Servers", value: { resource_types: { id: 5, name: "Servers", display: "Servers", resource_order: 5, category: 'onprem' } } },
            { "name": "Networks", count: 0, category: 'networks', filterName: [ "resource_types", "asset_types" ], "resource": "Networks", value: { resource_types: { id: 6, name: "Networks", display: "Networks", resource_order: 6, category: 'networks' }, 'asset_types': { id: 1, name: "Router", asset_order: 1 } } },
            { "name": "Citrix", count: 0, category: 'citrix', filterName: [ "resource_types" ], "resource": "Citrix", value: { resource_types: { id: 7, name: "Citrix", display: "Citrix", resource_order: 7, category: 'citrix' } } },
            { "name": "MasterCI", active_count: 0, count: 0, in_active_count: 0, category: 'MasterCI', filterName: ["status"], "resource": "MasterCI", value: { resource_types: { id: 8, name: "MasterCI", display: "MasterCI", resource_order: 8, category: 'MasterCI' }, status:{ id: "Active", name: "Active" } }  },
            { "name": "SFTP", count: 0, category: 'SFTP', filterName: [ "environments" ], "resource": "SFTP", value: { resource_types: { id: 9, name: "SFTP", display: "SFTP", resource_order: 9, category: 'SFTP' }, environments: { id: 1, name: "Non-PRD" } } },
            { "name": "Subscriptions", count: 0, category: 'Subscriptions', filterName: [], "resource": "Subscriptions", value: { resource_types: { id: 10, name: "Subscriptions", display: "Subscriptions", resource_order: 10, category: 'Subscriptions' } } },
            { "name": "VirtualMachine", count: 0, category: 'VirtualMachine', filterName: [], "resource": "VirtualMachine", value: { resource_types: { id: 10, name: "VirtualMachine", display: "VirtualMachine", resource_order: 10, category: 'VirtualMachine' } } },
            { "name": "S3", count: 0, category: 'cloud', filterName: [ "resource_types", "accounts" ], "resource": "S3", value: { resource_types: { id: 2, name: "S3", display: "S3", resource_order: 2, category: 'cloud' }, accounts: this.USRegiosACcounts } },
            { "name": "Account", count: 0, category: 'Account', filterName: [], "resource": "Account", value: { resource_types: { id: 2, name: "Account", display: "Account", resource_order: 2, category: 'Account' } } },
            { "name": "VPC", count: 0, category: 'VPC', filterName: [], "resource": "VPC", value: { resource_types: { id: 1, name: "VPC", display: "VPC", resource_order: 2, category: 'VPC' } } },
            { "name": "Digital", count: 0, category: 'Digital', filterName: [ "asset_type" ], "resource": "Digital", value: { resource_types: { id: 1, name: "Digital", display: "Digital", resource_order: 12, category: 'Digital' } , asset_type: { id: "all", name: "all" } } },
            
        ]
    }
    tableColumns = {
        EC2: {
            nonComplientFilters: [
                { label: 'Account', value: 'accounts' },
                // { label: 'Resource Type', value: 'resource_types' }
            ],
            nonComplientCriteria: "1. Has invalid/missing values in 'Application ID' and 'Environment' Tags \n 2. There is no data/relation in between the Application and it's Environment in Armada.",
            cols: [
                { field: 'resource_tags.name', header: 'Server Name', sort: false, type: 'NA', category: 'column', mandatory: true },
                { field: 'resource_tags.environment', header: 'Environment', sort: false, type: 'NA', category: 'column', mandatory: true },
                { field: 'status', header: 'Status', sort: true, type: 'NA', category: 'both', mandatory: true },
                { field: 'account_name', header: 'Account Name', sort: true, type: 'NA', category: 'both', mandatory: true },
                { field: 'instance_type', header: 'Instance Type', sort: true, type: 'NA', category: 'both', mandatory: true },
                { field: 'private_ip_address', header: 'IP Address', sort: true, type: 'NA', category: 'both', mandatory: true },
                { field: 'resource_tags.application_code', header: 'Application ID', sort: true, type: 'NA', category: 'expander' },
                { field: 'instance_id', header: 'Instance Id', sort: true, type: 'NA', category: 'expander' },
                { field: 'region', header: 'Region', sort: true, type: 'NA', category: 'expander' },
                { field: 'platform', header: 'Platform', sort: true, type: 'NA', category: 'expander' },
                { field: 'availabilty_zone', header: 'Availability Zone', sort: true, type: 'NA', category: 'expander' },
                { field: 'attachment_count', header: 'Attachment Count', sort: true, type: 'NA', category: 'expander' },
                { field: 'total_volume_size', header: 'Total Volume Size(GB)', sort: true, type: 'NA', category: 'expander' },
                { field: 'ami', header: 'AMI', sort: true, type: 'NA', category: 'expander' },
                { field: 'launch_time', header: 'Launch Time', sort: true, type: 'time', category: 'expander' },
                { field: 'public_ip_address', header: 'Public Ip Address', sort: true, type: 'NA', category: 'expander' },
                { field: 'subnet_id', header: 'Subnet Id', sort: true, type: 'NA', category: 'expander' },
                { field: 'vpc_id', header: 'VPC Id', sort: true, type: 'NA', category: 'expander' },
                { field: 'vcpu', header: 'vCPU', sort: true, type: 'NA', category: 'expander' },
                { field: 'memory', header: 'Memory(GB)', sort: true, type: 'memory', category: 'expander' },
                // { field: 'storage', header: 'Storage', sort: true, type: 'NA', category: 'expander' },
                { field: 'network_performance', header: 'Network Performance', sort: true, type: 'NA', category: 'expander' },
                { field: 'dedicated_ebs_bandwidth', header: 'Dedicated EBS Bandwidth(Mbps)', sort: true, type: 'NA', category: 'expander' },
                { field: 'last_updated', header: 'Last Updated Time', sort: true, type: 'time', category: 'expander' },
                {
                    field: '', header: 'AWS TAGS', isCategory: true, category: 'ignore', fields: [
                        { field: 'resource_tags.name', header: 'Server Name', type: 'NA' },
                        { field: 'resource_tags.resource_identifier', header: 'Identifier', type: 'NA' },
                        { field: 'resource_tags.resource_type', header: 'Type', type: 'NA' },
                        { field: 'resource_tags.application_code', header: 'Application Code', type: 'NA' },
                        { field: 'resource_tags.automation', header: 'Automation', type: 'NA' },
                        { field: 'resource_tags.budget_manager', header: 'Budget Manager', type: 'NA' },
                        { field: 'resource_tags.line_of_business_primary', header: 'Primary Business', type: 'NA' },
                        { field: 'resource_tags.environment', header: 'Environment', type: 'NA' },
                        { field: 'resource_tags.role', header: 'Role', type: 'NA' },
                        { field: 'resource_tags.created_by_tag', header: 'Created By', type: 'NA' },
                        { field: 'resource_tags.project', header: 'Project', type: 'NA' },
                        { field: 'resource_tags.security', header: 'Security' },
                        { field: 'resource_tags.termination_date', header: 'Termination Date', type: 'NA' },
                        { field: 'resource_tags.schedule', header: 'Schedule', type: 'NA' },
                        { field: 'resource_tags.monitor', header: 'Monitor', type: 'NA' },
                    ]
                },
                {
                    field: 'ec2_ebs', header: 'EBS', subHead: 'Ebs', isArray: true, category: 'ignore', fields: [
                        { field: 'ebs_volume_id', header: 'Volume ID', type: 'NA' }
                    ]
                }
            ]
        },
        RDS: {
            nonComplientFilters: [
                { label: 'Account', value: 'accounts' },
                // { label: 'Resource Type', value: 'resource_types' }
            ],
            nonComplientCriteria: "1. Has invalid/missing values in 'Application ID' and 'Environment' Tags \n 2. There is no data/relation in between the Application and it's Environment in Armada.",
            cols: [
                { field: 'resource_tags.name', header: 'Instance Name', sort: false, type: 'NA', category: 'column', mandatory: true },
                { field: 'resource_tags.environment', header: 'Environment', sort: false, type: 'NA', category: 'column', mandatory: true },
                { field: 'status', header: 'Status', sort: true, type: 'NA', category: 'column', mandatory: true },
                { field: 'account_name', header: 'Account Name', sort: true, type: 'NA', category: 'column', mandatory: true },
                { field: 'engine', header: 'Engine', sort: true, type: 'NA', category: 'column', mandatory: true },
                { field: 'engine_version', header: 'Engine Version', sort: true, type: 'NA', category: 'column', mandatory: true },
                { field: 'resource_tags.application_code', header: 'Application ID', sort: true, type: 'NA', category: 'expander' },
                { field: 'instance_class', header: 'Instance Class', sort: true, type: 'NA', category: 'expander' },
                { field: 'license_model', header: 'License Model', sort: true, type: 'NA', category: 'expander' },
                { field: 'allocated_storage', header: 'Allocated Storage in GB', sort: true, type: 'NA', category: 'expander' },
                { field: 'free_storage', header: 'Free Storage Space Bytes', sort: true, type: 'NA', category: 'expander' },
                { field: 'iops', header: 'IOPS', sort: true, type: 'NA', category: 'expander' },
                { field: 'region', header: 'Region Name', sort: true, type: 'NA', category: 'expander' },
                { field: 'endpoint', header: 'Endpoint', sort: true, type: 'NA', category: 'expander' },
                { field: 'created', header: 'Created', sort: true, type: 'NA', category: 'expander' },
                { field: 'backup_retention_period', header: 'Backup Retention Period in Days', sort: true, type: 'NA', category: 'expander' },
                { field: 'last_updated', header: 'Last Updated Time', sort: true, type: 'time', category: 'expander' },
                {
                    field: '', header: 'AWS TAGS', isCategory: true, category: 'ignore', fields: [
                        { field: 'resource_tags.application_code', header: 'Application Code', type: 'NA' },
                        { field: 'resource_tags.automation', header: 'Automation', type: 'NA' },
                        { field: 'resource_tags.budget_manager', header: 'Budget Manager', type: 'NA' },
                        { field: 'resource_tags.created_by_tag', header: 'Created By', type: 'NA' },
                        { field: 'resource_tags.environment', header: 'Environment', type: 'NA' },
                        { field: 'resource_tags.line_of_business_primary', header: 'Primary Business', type: 'NA' },
                        { field: 'resource_tags.monitor', header: 'Monitor', type: 'NA' },
                        { field: 'resource_tags.name', header: 'Instance Name', type: 'NA' },
                        { field: 'resource_tags.project', header: 'Project', type: 'NA' },
                        { field: 'resource_tags.role', header: 'Role', type: 'NA' },
                        { field: 'resource_tags.schedule', header: 'Schedule', type: 'NA' },
                        { field: 'resource_tags.security', header: 'Security', type: 'NA' },
                        { field: 'resource_tags.termination_date', header: 'Termination Date', type: 'time' },
                    ]
                },
                {
                    field: 'subnet', header: 'SUBNETS', subHead: 'Subnet', isArray: true, category: 'ignore', fields: [
                        { field: 'subnet_name', header: 'Subnet Name', type: 'NA' }
                    ]
                },
                {
                    field: 'availability_zone', header: 'Availability Zone', subHead: 'AVAILABILITY ZONE', isArray: true, category: 'ignore', fields: [
                        { field: 'az_name', header: 'Name', type: 'NA' }
                    ]
                }
            ]
        },
        EBS: {
            nonComplientFilters: [
                { label: 'Account', value: 'accounts' },
                // { label: 'Resource Type', value: 'resource_types' }
            ],
            nonComplientCriteria: "1. Has invalid/missing values in 'Application ID' and 'Environment' Tags \n 2. There is no data/relation in between the Application and it's Environment in Armada.",
            cols: [
                { field: 'resource_tags.name', header: 'Instance Name', sort: false, type: 'NA', category: 'column', mandatory: true },
                { field: 'resource_tags.environment', header: 'Environment', sort: false, type: 'NA', category: 'column', mandatory: true },
                { field: 'volume_id', header: 'Volume Ids', sort: true, type: 'NA', category: 'column', mandatory: true },
                { field: 'account_name', header: 'Account Name', sort: true, type: 'NA', category: 'column', mandatory: true },
                { field: 'ec2_instance_id', header: 'EC2 InstanceId', sort: true, type: 'NA', category: 'column', mandatory: true },
                { field: 'volume_type', header: 'Volume Type', sort: true, type: 'NA', category: 'column', mandatory: true },
                { field: 'resource_tags.application_code', header: 'Application ID', sort: true, type: 'NA', category: 'expander' },
                { field: 'state', header: 'State', sort: true, type: 'NA', category: 'expander' },
                { field: 'delete_on_termination', header: 'Delete On Termination', sort: true, type: 'NA', category: 'expander' },
                { field: 'device', header: 'Device', sort: true, type: 'NA', category: 'expander' },
                { field: 'attach_time', header: 'Attach Time', sort: true, type: 'time', category: 'expander' },
                { field: 'snapshot_id', header: 'Snapshot Id', sort: true, type: 'NA', category: 'expander' },
                { field: 'created_time', header: 'Create Time', sort: true, type: 'time', category: 'expander' },
                { field: 'iops', header: 'IOPS', sort: true, type: 'NA', category: 'expander' },
                { field: 'size', header: 'Size', sort: true, type: 'NA', category: 'expander' },
                // { field: 'aea', header: 'AEA', sort: true, type: 'NA', category: 'expander' },
                { field: 'availability_zone', header: 'Availability Zone', sort: true, type: 'NA', category: 'expander' },
                { field: 'encrypted', header: 'Encrypted', sort: true, type: 'NA', category: 'expander' },
                { field: 'last_updated', header: 'Last Updated Time', sort: true, type: 'time', category: 'expander' },
                // { field: 'is_active', header: 'Is Active', sort: true, type: 'NA', category: 'expander' },
                {
                    field: '', header: 'AWS TAGS', isCategory: true, category: 'ignore', fields: [
                        { field: 'resource_tags.application_code', header: 'Application Code', type: 'NA' },
                        { field: 'resource_tags.automation', header: 'Automation', type: 'NA' },
                        { field: 'resource_tags.budget_manager', header: 'Budget Manager', type: 'NA' },
                        { field: 'resource_tags.created_by_tag', header: 'Created By', type: 'NA' },
                        { field: 'resource_tags.environment', header: 'Environment', type: 'NA' },
                        { field: 'resource_tags.line_of_business_primary', header: 'Primary Business', type: 'NA' },
                        { field: 'resource_tags.monitor', header: 'Monitor', type: 'NA' },
                        { field: 'resource_tags.name', header: 'Instance Name', type: 'NA' },
                        { field: 'resource_tags.project', header: 'Project', type: 'NA' },
                        { field: 'resource_tags.role', header: 'Role', type: 'NA' },
                        { field: 'resource_tags.schedule', header: 'Schedule', type: 'NA' },
                        { field: 'resource_tags.security', header: 'Security', type: 'NA' },
                        { field: 'resource_tags.termination_date', header: 'Termination Date', type: 'time' },
                    ]
                }
            ]
        },
        LB: {
            nonComplientFilters: [
                { label: 'Account', value: 'accounts' },
                // { label: 'Resource Type', value: 'resource_types' }
            ],
            nonComplientCriteria: "1. Has invalid/missing values in 'Application ID' and 'Environment' Tags \n 2. There is no data/relation in between the Application and it's Environment in Armada.",
            cols: [
                { field: 'load_balancer_name', header: 'Load Balancer Name', sort: true, type: 'NA', category: 'column', mandatory: true },
                { field: 'load_balancer_type', header: 'Load Balancer Type', sort: true, type: 'NA', category: 'column', mandatory: true },
                { field: 'state', header: 'State', sort: true, type: 'NA', category: 'column', mandatory: true },
                { field: 'account_name', header: 'Account Name', sort: true, type: 'NA', category: 'column', mandatory: true },
                { field: 'resource_tags.environment', header: 'Environment', sort: false, type: 'NA', category: 'column', mandatory: true },
                { field: 'region', header: 'Region', sort: true, type: 'NA', category: 'column', mandatory: true },
                { field: 'resource_tags.application_code', header: 'Application ID', sort: true, type: 'NA', category: 'expander' },
                { field: 'access_logs', header: 'Access Logs', sort: true, type: 'NA', category: 'expander' },
                { field: 'deletion_protection', header: 'Deletion Protection', sort: true, type: 'NA', category: 'expander' },
                { field: 'dns_name', header: 'DNS Name', sort: true, type: 'NA', category: 'expander' },
                // { field: 'account', header: 'Account', sort: true, type: 'NA', category: 'expander' },
                { field: 'created_time', header: 'Create Time', sort: true, type: 'NA', category: 'expander' },
                { field: 'drop_invalid_header_fields', header: 'Drop Invalid Header Fields', sort: true, type: 'NA', category: 'expander' },
                { field: 'hosted_zone', header: 'Hosted Zone', sort: true, type: 'NA', category: 'expander' },
                // { field: 'aea', header: 'AEA', sort: true, type: 'NA', category: 'expander' },
                { field: 'http2', header: 'Http2', sort: true, type: 'NA', category: 'expander' },
                { field: 'idle_timeout', header: 'Idle Timeout', sort: true, type: 'NA', category: 'expander' },
                // { field: 'is_active', header: 'Active', sort: true, type: 'NA', category: 'expander' },
                // { field: 'listener_id', header: 'Listener ID', sort: true, type: 'NA', category: 'expander' },
                // { field: 'rules', header: 'Rules', sort: true, type: 'NA', category: 'expander' },
                { field: 'vpc_id', header: 'VPC ID', sort: true, type: 'NA', category: 'expander' },
                { field: 'last_updated', header: 'Last Updated Time', sort: true, type: 'time', category: 'expander' },
                // { field: 'is_active', header: 'Is Active', sort: true, type: 'NA', category: 'expander' },
                {
                    field: '', header: 'AWS TAGS', isCategory: true, category: 'ignore', fields: [
                        { field: 'resource_tags.application_code', header: 'Application Code', type: 'NA' },
                        { field: 'resource_tags.automation', header: 'Automation', type: 'NA' },
                        { field: 'resource_tags.budget_manager', header: 'Budget Manager', type: 'NA' },
                        { field: 'resource_tags.created_by_tag', header: 'Created By', type: 'NA' },
                        { field: 'resource_tags.environment', header: 'Environment', type: 'NA' },
                        { field: 'resource_tags.line_of_business_primary', header: 'Primary Business', type: 'NA' },
                        { field: 'resource_tags.monitor', header: 'Monitor', type: 'NA' },
                        { field: 'resource_tags.resource_identifier', header: 'Resource Identifier', type: 'NA' },
                        { field: 'resource_tags.name', header: 'Instance Name', type: 'NA' },
                        { field: 'resource_tags.project', header: 'Project', type: 'NA' },
                        { field: 'resource_tags.role', header: 'Role', type: 'NA' },
                        { field: 'resource_tags.schedule', header: 'Schedule', type: 'NA' },
                        { field: 'resource_tags.security', header: 'Security', type: 'NA' },
                        { field: 'resource_tags.termination_date', header: 'Termination Date', type: 'time' },
                    ]
                },
                {
                    field: 'subnet', header: 'SUBNETS', subHead: 'Subnet', isArray: true, category: 'ignore', fields: [
                        { field: 'subnet_name', header: 'Subnet Name', type: 'NA' }
                    ]
                }, {
                    field: 'availability_zone', header: 'AVAILABILITY ZONES', subHead: 'Availability Zone', isArray: true, category: 'ignore', fields: [
                        { field: 'az_name', header: 'Availability Zone Name', type: 'NA' }
                    ]
                }, {
                    field: 'listener_rules', header: 'LISTENERS', subHead: 'Listener', isArray: true, category: 'ignore', fields: [
                        { field: 'listener_id', header: 'Listener ID', type: 'NA' },
                        {
                            field: 'listener_rules', header: 'Listener Rules', isArray: true, cols: [ { name: 'Rule Id', class: 'table-col-wd25' }, { name: 'Rule Info', class: '' } ], category: 'ignore', fields: [
                                { field: 'rule_id', header: 'Rule Id', type: 'NA' },
                                { field: 'rule_info', header: 'Rule Info', type: 'NA' }
                            ]
                        }
                    ]
                }
            ]
        },
        Servers: {
            nonComplientFilters: [
                { label: 'Account', value: 'accounts' },
                // { label: 'Resource Type', value: 'resource_types' }
            ],
            nonComplientCriteria: "1. Has invalid/missing values in 'Application ID' and 'Environment' Tags \n 2. There is no data/relation in between the Application and it's Environment in Armada.",
            cols: [
                { field: 'server_name', header: 'Server Name', sort: true, type: 'NA', category: 'column', mandatory: true },
                { field: 'power_state', header: 'Power State', sort: true, type: 'NA', category: 'column' },
                { field: 'status', header: 'Status', sort: true, type: 'NA', category: 'column', mandatory: true },
                { field: 'app_id', header: 'Application ID', sort: true, type: 'NA', category: 'column', mandatory: true },
                { field: 'environment_id', header: 'Environment', sort: true, type: 'NA', category: 'column', mandatory: true },
                { field: 'cluster', header: 'Cluster', sort: true, type: 'NA', category: 'expander', mandatory: true },
                { field: 'datastore', header: 'Data Store', sort: true, type: 'NA', category: 'expander', mandatory: true },
                { field: 'dnshost_name', header: 'DNS Host Name', sort: true, type: 'NA', category: 'expander' },
                { field: 'description', header: 'Description', sort: true, type: 'NA', category: 'expander' },
                { field: 'esx_host', header: 'ESX Host', sort: true, type: 'NA', category: 'expander' },
                { field: 'hardware_type', header: 'Hardware Type', sort: true, type: 'NA', category: 'expander' },
                { field: 'ip', header: 'IP Address', sort: true, type: 'NA', category: 'expander' },
                { field: 'location', header: 'Location', sort: true, type: 'NA', category: 'expander' },
                { field: 'logical_cores', header: 'Logical Cores', sort: true, type: 'NA', category: 'expander' },
                { field: 'memory_gb', header: 'Memory(GB)', sort: true, type: 'NA', category: 'expander' },
                { field: 'operating_system', header: 'Operating System', sort: true, type: 'NA', category: 'expander' },
                { field: 'physical_cores', header: 'Physical Cores', sort: true, type: 'NA', category: 'expander' },
                { field: 'role', header: 'Role', sort: true, type: 'NA', category: 'expander' },
                { field: 'uuid', header: 'UUID', sort: true, type: 'NA', category: 'expander' },
                { field: 'vc', header: 'VC', sort: true, type: 'NA', category: 'expander' },
                { field: 'version', header: 'Version', sort: true, type: 'NA', category: 'expander' },
                { field: 'last_updated', header: 'Last Updated Time', sort: true, type: 'time', category: 'expander' },
            ]
        },
        Networks: {
            nonComplientFilters: [
                { label: 'Account', value: 'accounts' },
                // { label: 'Resource Type', value: 'resource_types' }
            ],
            nonComplientCriteria: "1. Has invalid/missing values in 'Application ID' and 'Environment' Tags \n 2. There is no data/relation in between the Application and it's Environment in Armada.",
            cols: [
                { field: 'serial_number', header: 'Serial N.O', sort: true, type: 'NA', category: 'column', mandatory: true },
                { field: 'vendor', header: 'Vendor', sort: true, type: 'NA', category: 'column', mandatory: true },
                { field: 'ip_address', header: 'IP Address', sort: true, type: 'NA', category: 'column', mandatory: true },
                { field: 'display_name', header: 'Display Name', sort: true, type: 'NA', category: 'column', mandatory: true },
                { field: 'domain', header: 'Domain', sort: true, type: 'NA', category: 'column', mandatory: true },
                { field: 'firmware', header: 'Firmware', sort: true, type: 'NA', category: 'column', mandatory: true },
                { field: 'acquisition_date', header: 'Acquisition Date', sort: true, type: 'time', category: 'expander' },
                { field: 'asset_state', header: 'Asset State', sort: true, type: 'NA', category: 'expander' },
                { field: 'asset_tag', header: 'Asset Tag', sort: true, type: 'NA', category: 'expander' },
                { field: 'asset_type', header: 'Asset Type', sort: true, type: 'NA', category: 'expander' },
                { field: 'assigned_on', header: 'Assigned On', sort: true, type: 'NA', category: 'expander' },
                { field: 'assignment_section', header: 'Assignment Section', sort: true, type: 'NA', category: 'expander' },
                { field: 'attach_a_file', header: 'Attach File', sort: true, type: 'NA', category: 'expander' },
                { field: 'cost', header: 'Cost', sort: true, type: 'NA', category: 'expander' },
                { field: 'cost_section', header: 'Cost Section', sort: true, type: 'NA', category: 'expander' },
                { field: 'is_active', header: 'Active', sort: true, type: 'NA', category: 'expander' },
                { field: 'created_by', header: 'Created By', sort: true, type: 'NA', category: 'expander' },
                { field: 'department', header: 'Department', sort: true, type: 'NA', category: 'expander' },
                { field: 'depreciation_type', header: 'Depreciation Type', sort: true, type: 'NA', category: 'expander' },
                { field: 'description', header: 'Description', sort: true, type: 'NA', category: 'expander' },
                { field: 'firmware_version', header: 'Firmware Version', sort: true, type: 'NA', category: 'expander' },
                { field: 'hardware_properties', header: 'Hardware Properties', sort: true, type: 'NA', category: 'expander' },
                { field: 'impact', header: 'Impact', sort: true, type: 'NA', category: 'expander' },
                { field: 'created_at', header: 'Created At', sort: true, type: 'time', category: 'expander' },
                { field: 'last_audit_date', header: 'Last Audit Date', sort: true, type: 'time', category: 'expander' },
                { field: 'layer', header: 'Layer', sort: true, type: 'NA', category: 'expander' },
                { field: 'location', header: 'Location', sort: true, type: 'NA', category: 'expander' },
                { field: 'mac_address', header: 'MAC Address', sort: true, type: 'NA', category: 'expander' },
                { field: 'managed_by_group', header: 'Manage By Group', sort: true, type: 'NA', category: 'expander' },
                { field: 'modified_at', header: 'Modified At', sort: true, type: 'NA', category: 'expander' },
                { field: 'ports', header: 'Ports', sort: true, type: 'NA', category: 'expander' },
                { field: 'product', header: 'Product', sort: true, type: 'NA', category: 'expander' },
                { field: 'properties', header: 'Properties', sort: true, type: 'NA', category: 'expander' },
                { field: 'salvage', header: 'Salvage', sort: true, type: 'NA', category: 'expander' },
                { field: 'subnet_mask', header: 'Subnet MAsk', sort: true, type: 'NA', category: 'expander' },
                { field: 'updated_by', header: 'Updated By', sort: true, type: 'NA', category: 'expander' },
                { field: 'usage_type', header: 'Usage Type', sort: true, type: 'NA', category: 'expander' },
                { field: 'used_by', header: 'Used By', sort: true, type: 'NA', category: 'expander' },
                { field: 'warranty', header: 'Warranty', sort: true, type: 'NA', category: 'expander' },
                { field: 'warranty_expired_date', header: 'Warranty Expired Date', sort: true, type: 'time', category: 'expander' },
            ]
        },
        Citrix: {
            nonComplientFilters: [
                { label: 'Account', value: 'accounts' },
                // { label: 'Resource Type', value: 'resource_types' }
            ],
            nonComplientCriteria: "1. Has invalid/missing values in 'Application ID' and 'Environment' Tags \n 2. There is no data/relation in between the Application and it's Environment in Armada.",
            cols: [
                { field: 'machine_name', header: 'Machine Name', sort: true, type: 'NA', category: 'column', mandatory: true },
                { field: 'environment', header: 'Environment', sort: true, type: 'NA', category: 'column', mandatory: true },
                { field: 'application_name', header: 'Application Name', sort: true, type: 'NA', category: 'column', mandatory: true },
                { field: 'application_type', header: 'Application Type', sort: true, type: 'NA', category: 'column', mandatory: true },
                { field: 'provisioning_type', header: 'Provisioning Type', sort: true, type: 'NA', category: 'expander' },
                { field: 'agent_version', header: 'Agent Version', sort: true, type: 'NA', category: 'expander' },
                { field: 'os_type', header: 'OS Type', sort: true, type: 'NA', category: 'expander' },
                { field: 'is_active', header: 'Active', sort: true, type: 'NA', category: 'expander' },
                { field: 'application_id', header: 'Application ID', sort: true, type: 'NA', category: 'expander' },
            ]
        },
        MasterCI: {
            nonComplientFilters: [
                { label: 'Account', value: 'accounts' },
            ],
            nonComplientCriteria: "1. Has invalid/missing values in 'Application ID' and 'Environment' Tags \n 2. There is no data/relation in between the Application and it's Environment in Armada.",
            cols: [
                { field: 'app_name', header: 'Application Name', sort: true, type: 'NA', category: 'column', mandatory: true },
                { field: 'app_code', header: 'Application ID', sort: true, type: 'NA', category: 'column', mandatory: true },
                { field: 'app_owner', header: 'Application Owner', sort: true, type: 'NA', category: 'column', mandatory: true },
                { field: 'dev_manager', header: 'Development Manager', sort: true, type: 'NA', category: 'column', mandatory: true },
                { field: 'budget_manager', header: 'Budget Manager', sort: true, type: 'NA', category: 'column', mandatory: true },
                { field: 'line_of_business', header: 'Line of Business', sort: true, type: 'NA', category: 'expander' },
                { field: 'description', header: 'Description', sort: true, type: 'NA', category: 'expander' },
                { field: 'business_criticality', header: 'Business Criticality', sort: true, type: 'NA', category: 'expander' },
                { field: 'technology_division', header: 'Tech Division', sort: true, type: 'NA', category: 'expander' },
                { field: 'status', header: 'Status', sort: true, type: 'NA', category: 'expander' },
                { field: 'hosted_solution', header: 'Hosted Solution', sort: true, type: 'NA', category: 'expander' },
                { field: 'rto', header: 'RTO', sort: true, type: 'NA', category: 'expander' },
                { field: 'pii', header: 'Personal Information (PI)', sort: true, type: 'NA', category: 'expander' },
                { field: 'rpo', header: 'RPO', sort: true, type: 'NA', category: 'expander' },
                { field: 'user_credentials', header: 'User Credentials', sort: true, type: 'NA', category: 'expander' },
                { field: 'application_access_method', header: 'Application Access Method', sort: true, type: 'NA', category: 'expander' },
                { field: 'application_type', header: 'Application Type', sort: true, type: 'NA', category: 'expander' },
                { field: 'application_lifecycle_status', header: 'Application Lifecycle Status', sort: true, type: 'NA', category: 'expander' },
                { field: 'vendor', header: 'Vendor', sort: true, type: 'NA', category: 'expander' },
                { field: 'technology_support_group_level_1', header: 'Technology Support Group Level 1', sort: true, type: 'NA', category: 'expander' },
                { field: 'technology_support_group_level_2', header: 'Technology Support Group Level 2', sort: true, type: 'NA', category: 'expander' },
                { field: 'technology_support_group_level_3', header: 'Technology Support Group Level 3', sort: true, type: 'NA', category: 'expander' },
                // { field: 'service_desk_supported', header: 'Service Desk Supported', sort: true, type: 'NA', category: 'expander' },
                { field: 'technology_operations_supported', header: 'Technology Operations Supported', sort: true, type: 'NA', category: 'expander' },
                { field: 'qa_supported', header: 'QA Supported', sort: true, type: 'NA', category: 'expander' },
                { field: 'impact', header: 'Impact', sort: true, type: 'NA', category: 'expander' },
                { field: 'assigned_on', header: 'Assigned On', sort: true, type: 'NA', category: 'expander' },
                { field: 'application_usage_location', header: 'Application Usage Location', sort: true, type: 'NA', category: 'expander' },
                { field: 'public', header: 'Public', sort: true, type: 'NA', category: 'expander' },
                { field: 'dns', header: 'DNS', sort: true, type: 'NA', category: 'expander' },
                { field: 'additional_comments', header: 'Additional Notes', sort: true, type: 'NA', category: 'expander' },
            ]
        },
        SFTP: {
            cols: [
                { field: 'username', header: 'Username', type: 'NA', category: 'column', mandatory: true },
                { field: 'email', header: 'Email', type: 'NA', category: 'column', mandatory: true },
                { field: 'pubkey', header: 'Pub Key', type: 'NA', category: 'column', mandatory: true },
                { field: 'accountCreated', header: 'Created Date', type: 'NA', category: 'column', mandatory: true },
                { field: 'accountUpdated', header: 'Updated Date', type: 'NA', category: 'column', mandatory: true },
                { field: 'parentdir', header: 'Parent Dir', type: 'NA', category: 'expander' },
                {
                    field: 'directoryMap', header: 'Directory Map', subHead: 'directoryMap', isArray: true, category: 'ignore', fields: [
                        { field: 'Target', header: 'Target', type: 'NA' },
                        { field: 'Entry', header: 'Entry', type: 'NA' }
                    ]
                }
            ]
        },
        Subscriptions: {
            cols: [
                { field: 'subscription_id', header: 'Subscription ID', type: 'NA', category: 'column', mandatory: true },
                { field: 'display_name', header: 'Subscription Name', type: 'NA', category: 'column', mandatory: true },
                { field: 'tenant_id', header: 'Tenant ID', type: 'NA', category: 'column', mandatory: true },
                { field: 'subscription_owner', header: 'Subscription Owner', type: 'NA', category: 'column', mandatory: true },
                { field: 'offer', header: 'Offer', type: 'NA', category: 'column', mandatory: true },
                { field: 'directory', header: 'Directory', type: 'NA', category: 'column', mandatory: true },
                { field: 'status', header: 'Status', type: 'NA', category: 'column', mandatory: true },
                // { field: 'created_date', header: 'Created Date', type: 'NA', category: 'column', mandatory: true },
                { field: 'last_updated', header: 'Updated Date', type: 'NA', category: 'column', mandatory: true }
            ]
        },
        VirtualMachine: {
            cols: [
                { field: 'resource_group_name', header: 'Resource Group', type: 'NA', category: 'column', mandatory: true },
                { field: 'vm_name', header: 'VM Name', type: 'NA', category: 'column', mandatory: true },
                { field: 'display_name', header: 'Display Name', type: 'NA', category: 'column', mandatory: true },
                { field: 'vm_size', header: 'VM Size', type: 'NA', category: 'column', mandatory: true },
                { field: 'private_ip', header: 'Private IP', type: 'NA', category: 'column', mandatory: true },
                { field: 'osi_image_type', header: 'OSI Image Type', type: 'NA', category: 'expander' },
                { field: 'admin_user_name', header: 'Admin', type: 'NA', category: 'column', mandatory: true },
                { field: 'os_disk', header: 'OS Disk', type: 'NA', category: 'column', mandatory: true },
                { field: 'nic_id', header: 'NIC ID', type: 'NA', category: 'expander' },
                { field: 'managed_disk_uri', header: 'Managed Disk', type: 'NA', category: 'expander' },
                { field: 'unmanaged_disk_uri', header: 'Unmanaged Disk', type: 'NA', category: 'expander' },
                { field: 'data_disk_names', header: 'Data Disk Names', type: 'NA', category: 'expander' },
                { field: 'os_version', header: 'OS version', type: 'NA', category: 'expander' },
                { field: 'vm_status', header: 'VM Status', type: 'NA', category: 'column', mandatory: true },
                { field: 'is_active', header: 'Status', type: 'NA', category: 'column', mandatory: true },
                { field: 'last_updated', header: 'Last Updated', type: 'NA', category: 'column', mandatory: true }
            ]
        },
        S3: {
            nonComplientFilters: [
                // { label: 'Resource Type', value: 'resource_types' },
                { label: 'Account', value: 'accounts' }
            ],
            nonComplientCriteria: "1. Has invalid/missing values in 'Application ID' and 'Environment' Tags \n 2. There is no data/relation in between the Application and it's Environment in Armada.",
            cols: [
                // { field: 'id', header: 'ID', sort: true, type: 'NA', category: 'column', mandatory: true },
                { field: 'resource_tags.application_code', header: 'Application ID', sort: true, type: 'NA', category: 'column' },
                { field: 'account_name', header: 'Account Name', sort: true, type: 'NA', category: 'column', mandatory: true },
                { field: 'bucket_name', header: 'Bucket Name', sort: true, type: 'NA', category: 'column', mandatory: true },
                { field: 'bucket_owner', header: 'Bucket Owner', sort: true, type: 'NA', category: 'column', mandatory: true },
                { field: 'account_region', header: 'Bucket Region', sort: true, type: 'NA', category: 'column', mandatory: true },
                { field: 'resource_tags.environment', header: 'Environment', sort: false, type: 'NA', category: 'column', mandatory: true },
                { field: 'last_updated', header: 'Last Updated Time', sort: true, type: 'time', category: 'expander' },
                {
                    field: '', header: 'AWS TAGS', isCategory: true, category: 'ignore', fields: [
                        { field: 'resource_tags.application_code', header: 'Application Code', type: 'NA' },
                        { field: 'resource_tags.automation', header: 'Automation', type: 'NA' },
                        { field: 'resource_tags.budget_manager', header: 'Budget Manager', type: 'NA' },
                        { field: 'resource_tags.created_by_tag', header: 'Created By', type: 'NA' },
                        { field: 'resource_tags.environment', header: 'Environment', type: 'NA' },
                        { field: 'resource_tags.line_of_business_primary', header: 'Primary Business', type: 'NA' },
                        { field: 'resource_tags.monitor', header: 'Monitor', type: 'NA' },
                        { field: 'resource_tags.resource_identifier', header: 'Resource Identifier', type: 'NA' },
                        { field: 'resource_tags.name', header: 'Instance Name', type: 'NA' },
                        { field: 'resource_tags.project', header: 'Project', type: 'NA' },
                        { field: 'resource_tags.role', header: 'Role', type: 'NA' },
                        { field: 'resource_tags.schedule', header: 'Schedule', type: 'NA' },
                        { field: 'resource_tags.security', header: 'Security', type: 'NA' },
                        // { field: 'resource_tags.termination_date', header: 'Termination Date', type: 'time' },
                    ]
                },
                //     {
                //         field: 'availability_zone', header: 'Availability Zone', subHead: 'AVAILABILITY ZONE', isArray: true, category: 'ignore', fields: [
                //             { field: 'az_name', header: 'Name', type: 'NA' }
                //         ]
                //     }availability_zone
            ]
        },
        Account: {

            // cols: [
            //     { field: 'account_name', header: 'Account Name', type: 'NA', category: 'column', mandatory: true },
            //     { field: 'account_number', header: 'Account Number', type: 'NA', category: 'column', mandatory: true },
            //     { field: 'cross_role_arn', header: 'Cross Role ARN', type: 'NA', category: 'column', mandatory: true },
            //     { field: 'is_active', header: 'Active', type: 'NA', category: 'column', mandatory: true },
            //     { field: 'region', header: 'Region', type: 'NA', category: 'column', mandatory: true }

            // ]
            cols: [
                { field: 'account_number', header: 'AWS Account', type: 'NA', category: 'column', mandatory: true },
                { field: 'account_name', header: 'Account Name', type: 'NA', category: 'column', mandatory: true },
                { field: 'account_friendly_name', header: 'AWS Account Name (Friendly)', type: 'NA', category: 'expander' },
                { field: 'region.region_name', header: 'Region', type: 'NA', category: 'column', mandatory: true },
                { field: 'availability_zone', header: 'Availability Zone', type: 'NA', category: 'expander' },
                { field: 'date_created', header: 'Date Created', type: 'NA', category: 'expander' },
                { field: 'cost_center', header: 'Cost Center', type: 'NA', category: 'expander' },
                { field: 'department', header: 'Department', type: 'NA', category: 'expander' },
                { field: 'cross_role_arn', header: 'Cross Role ARN', type: 'NA', category: 'expander' },
                { field: 'lob', header: 'LOB', type: 'NA', category: 'expander' },
                { field: 'account_owner', header: 'Account Owner', type: 'NA', category: 'expander' },
                { field: 'cia_accountable', header: 'CIA Accountable', type: 'NA', category: 'column', mandatory: true },
                { field: 'status', header: 'Status', type: 'NA', category: 'column', mandatory: true },
                { field: 'technical_account_owner', header: 'Technical Account Owner', type: 'NA', category: 'expander', mandatory: true },
                { field: 'actual_budget_owner', header: 'Actual Budget Owner', type: 'NA', category: 'expander', mandatory: true },
                { field: 'notes', header: 'Notes', type: 'NA', category: 'expander', mandatory: true },
                { field: 'account_type', header: 'Account Type', type: 'NA', category:'expander', mandatory: true },
                { field: 'player_id', header: 'Payer ID', type: 'NA', category:'expander', mandatory: true }

            ]
        },
        VPC: {
            cols: [
                { field: 'vpc_id', header: 'VPC ID', type: 'NA', category: 'column', mandatory: true },
                { field: 'vpc_name', header: 'VPC Name', type: 'NA', category: 'column', mandatory: true },
                { field: 'account_name', header: 'Account Name', type: 'NA', category: 'column', mandatory: true },
                { field: 'cidr_block', header: 'CIDR Block', type: 'NA', category: 'column', mandatory: true },
                { field: 'subnets_count', header: 'Subnet Count', type: 'NA', category: 'column', mandatory: true },
                { field: 'dhcp_options_id', header: 'DHCP ID', type: 'NA', category: 'column', mandatory: true },
                { field: 'ec2_instances_count', header: 'EC2 Instances', type: 'NA', category: 'column', mandatory: true },
                // { field: 'optionset_id', header: 'OptionSet ID', type: 'NA', category: 'column', mandatory: true },
                { field: 'state', header: 'State', type: 'NA', category: 'column', mandatory: true },
                // { field: 'aea', header: 'AEA', type: 'NA', category: 'column', mandatory: true },
                {
                    field: '', header: 'VPC TAGS', isCategory: true, category: 'ignore', fields: [
                        { field: 'resource_tags.resource_identifier', header: 'Identifier', type: 'NA' },
                        { field: 'resource_tags.name', header: 'Name', type: 'NA' },
                        { field: 'resource_tags.automation', header: 'Automation', type: 'NA' },
                        { field: 'resource_tags.environment', header: 'Environment' },
                        { field: 'resource_tags.application_code', header: 'Application Code', type: 'NA' },

                    ]
                },

            ]
        },
        Digital: {
            cols: [
                { field: 'name', header: 'Product', type: 'NA', category: 'column', mandatory: true },
                { field: 'user_id', header: 'Used By', type: 'NA', category: 'column', mandatory: true },
                { field: 'type_fields.serial_number', header: 'Serial Number', type: 'NA', category: 'column', mandatory: true },
                { field: 'type_fields.model_number', header: 'Model Number', type: 'NA', category: 'column', mandatory: true },
                { field: 'asset_type', header: 'Asset Type', type: 'NA', category: 'column', mandatory: true },
                {
                    field: '', header: 'Hardware', isCategory: true, category: 'ignore', fields: [
                        { field: 'type_fields.serial_number', header: 'Serial Number', type: 'NA' },
                        { field: 'type_fields.asset_state', header: 'Asset State', type: 'NA'},
                        { field: 'type_fields.model_number', header: 'Model Number', type: 'NA' },
                        { field: 'type_fields.product', header: 'Product', type: 'NA' },
                        { field: 'type_fields.cost', header: 'Cost', type: 'NA' },
                        { field: 'usage_type', header: 'Usage Type', type: 'NA' },
                        { field: 'workspace_id', header: 'WorkSpace ID' },
                        { field: 'discovery_enabled', header: 'Discovery Enabled', type: 'NA' },

                    ]
                },
            ]
        },
    };
    resourceTiles = {
        order: []
    }
    requestStatusConfig = {
        instance_start_stop: [
            {
                label: 'Awaiting Approval',
                value: 'awaiting approval',
                index: 1
            },
            {
                label: 'Approved',
                value: 'approved',
                index: 2
            },
            {
                label: 'Auto Approved',
                value: 'auto approved',
                index: 3
            },
            {
                label: 'Rejected',
                value: 'rejected',
                index: 4
            },
            {
                label: 'Completed',
                value: 'completed',
                index: 5
            }
        ]
    }
}
